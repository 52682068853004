import axios from "axios";

export class ApiService {
    
    //private apiUrl: string;
    private static serverURL:string="";

    public static setServerURL(url:string):void{
        this.serverURL=url;
    }

    public static async callToServer(category: string, serviceName: string, version: string, data?:any){
        try{
            const tokenConfig = {
                headers: { Authorization: `Basic ${btoa("wdemo_portal:password")}` }
            };
            const tokenResponse= await axios.post(this.serverURL+'/service/auth/getToken/v1',undefined,tokenConfig);
            const requestConfig = {
                headers: { Authorization: `Bearer ${tokenResponse.data.data.access_token}` }
            };
            const response= await axios.post(this.serverURL+'/service/'+category+'/'+serviceName+'/'+version,data,requestConfig);
            return response.data.data
        }catch(err:any){
            console.error(err);
            throw new Error(err);
        }
    }
}