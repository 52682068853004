import React, { Suspense } from 'react';
import {BrowserRouter, BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import IContentState from './IContentState';
import IContentProps from './IContentProps';
import App from "../../App";
import ErrorComponent from "../../wDocs/ErrorComponent/ErrorComponent";

export default class Content extends React.Component<IContentProps, IContentState> {

  state = { item: ""};

  constructor(props : any) {
    super(props);
    console.log("constructor CONTENT");
  }

  private _action(action: string) {
    switch (action.toUpperCase()) {
      case "EXIT":
        App.handleLogout();
        break;
    }
  }

  public SetNewValue(value: string) {
    this.setState({item: value});
  }

  componentDidMount() {
    this.loadComponentFromQueryParams();
  }

  loadComponentFromQueryParams() {
    this.SetNewValue("wDocs");
  }

  render() {
    console.log("render CONTENT");
    console.log(`/src/functions/${this.state.item}/${this.state.item}`);
    const LazyComponent = React.lazy(() => import(`/src/${this.state.item}/${this.state.item}`));
    return (
        <Router>
          <Suspense fallback={<div>Loading...{this.state.item}</div>}>
            <Routes>
            <Route path="/" element={<LazyComponent onAction={(act: any) => this._action(act)}/>}></Route>
            </Routes>
          </Suspense>
        </Router>
      );
  }
}
