import React, { Component } from 'react';
import IHeaderProps from './IHeaderProps';
import "./Header.css";
import App from "../../App";

export default class Header extends Component<IHeaderProps> {
  private static accountLogged:string="";
  private title:string="wDesk";
  private subTitle:string=App.companyName;

  exit(){
    App.handleLogout();
  }

  public static setAccount(account:string){
    Header.accountLogged=account;
  }

  render() {
    return (
        <div className="WDHeader fixed-top d-flex justify-content-between align-items-center px-2">
          <div className="d-flex align-items-center">
            <div className="logo" onClick={() => {
              window.location.reload();
            }}></div>
            <h2 className="text-white">
              &nbsp;&nbsp;{this.title}-
              <small>{this.subTitle !== "" ? this.subTitle : ``}</small>
            </h2>
          </div>
          <small>Version {App.version}</small>
          {Header.accountLogged !== "" &&
              <span className="text-white d-flex align-items-center">
            <i className="fas fa-user"></i>
                &nbsp;&nbsp;{Header.accountLogged}&nbsp;&nbsp;
                <i className="fas fa-sign-out-alt" onClick={() => this.exit()}></i>
        </span>
          }
        </div>
    );
  }

}
