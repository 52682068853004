import ReactDOM from 'react-dom';
import './index.css'; // Assicurati che questo venga prima dell'importazione di Bootstrap per sovrascrivere i CSS di default se necessario
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa qui il CSS di Bootstrap
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css'; // Assicurati di avere un file CSS per gli stili personalizzati

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);
