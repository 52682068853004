import React from 'react';
import IErrorComponentProps from "./IErrorComponentProps";
import ISessionDataModel from '../ISessionDataModel';
import IErrorComponentState from './IErrorComponentState';
import ResultState from "./ErrorComponentState.json";
import "./ErrorComponent.css";
import App from "../../App";


export default class ErrorComponent extends React.Component<IErrorComponentProps, ISessionDataModel & IErrorComponentState> {
  constructor(props: any) {
    super(props);
    if (this.props.data) {
      this.state = {
        ...this.props.data,
        ...ResultState
      }
    }
  }

  componentDidMount(): void {
    document.title="Non autorizzato-wDesk"
  }

  componentWillUnmount(): void {
    document.title="wDesk"
  }
  render() {

    console.log("Entrato nel render");
    return (
        <div className="scrollable-content mt-5 pt-3">
          <div>
            <h1>Non sei autorizzato ad accedere a questo tenant.</h1>
            <button className="btn btn-primary" onClick={() => {
              App.handleLogout();
            }}>Esci
            </button>
          </div>
        </div>
    )
  }

}

