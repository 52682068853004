import IConfiguration from "./IConfiguration";

export class Configuration {
  
  private static _instance: IConfiguration | null = null;

  public static Load(data: any) {
    Configuration._instance = data;
  }

  public static get Instance(): IConfiguration | null{
    return Configuration._instance;
  }

}